export default function bufferToBase64(
  buffer: number[],
): string {
  const binary = buffer
    .map((byte) => String.fromCharCode(byte))
    .join('')
  return `data:image/png;base64,${btoa(binary)}`
}

export function bufferToBase64Btoa(
  buffer: ArrayBuffer,
  type: string,
): string {
  const prefix =
    type == 'pdf'
      ? 'data:application/pdf;base64,'
      : 'data:image/png;base64,'

  return (
    prefix +
    btoa(
      new Uint8Array(buffer).reduce(
        (data, byte) => data + String.fromCharCode(byte),
        '',
      ),
    )
  )
}
